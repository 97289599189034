import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { GeoContext } from '../state/geo'
import DefaultLayout from '../layouts/defaultLayout/defaultLayout'
import TextPage from '../components/textPage/textPage'
import BackButton from '../components/backButton/backButton'
import ImprintDecorImg from '../assets/images/imprint_bg.inline.svg'

const Imprint: React.FC = () => {
  const { url } = useContext(GeoContext)
  return (
    <DefaultLayout hasNav={false} hasSearch={false} seo={{ title: 'Imprint' }}>
      <TextPage>
        <BackButton />
        <ImprintDecorImg className="text-page__decor" />
        <h1 className="h1 h--decor text-page__title">Imprint</h1>
        <p>
          <strong>
            Merck KGaA
            <br />
            Frankfurter Strasse 250
            <br />
            64293 Darmstadt
            <br />
            Germany
            <br />
            <br />
            Phone: +49 6151 72-0
            <br />
            Telefax: +49 6151 72-2000
            <br />
            <br />
            <a
              href={`https://www.${url}group.com`}
              target="_blank"
              rel="noopener noreferrer"
            >
              www.{url}group.com
            </a>
            <br />
            <a href={`mailto:service@${url}group.com`}>
              service@{url}group.com
            </a>
            <br />
          </strong>
        </p>

        <h2 className="h3 h--decor text-page__section-header">Details</h2>
        <p>
          <strong>Legal form:</strong> Corporation with general partners
          <br />
          <strong>Commercial Register:</strong> AG Darmstadt HRB 6164
          <br />
          <strong>Registered Office:</strong> Darmstadt
          <br />
          <strong>Chairman of the Supervisory Board:</strong> Dr. Wolfgang
          Büchele
          <br />
          <strong>Executive Board:</strong>
          <br />
          Belén Garijo, MD (Chair &amp; CEO)
          <br />
          Dr. Kai Beckmann (CEO Electronics)
          <br />
          Peter Guenter (CEO Healthcare)
          <br />
          Dr. Matthias Heinzel (CEO Life Science)
          <br />
          Dr. Marcus Kuhnert (CFO)
          <br />
        </p>
        <p>
          <strong>
            Value Added Tax Identification Number (VAT ID): DE 811850788
          </strong>
        </p>
        <p>
          Competent Governmental Authority: Regierungspräsidium Darmstadt
          (Germany)
        </p>
        <p>
          <strong>
            Responsible for the content according to § 55 Abs. 2 RStV (German):
          </strong>
          <br />
          Markus Kaiser
          <br />
          Merck KGaA
          <br />
          Frankfurter Straße 250
          <br />
          64293 Darmstadt
          <br />
          Germany
        </p>
        <p>
          You may also have a look at our{' '}
          <Link to="/privacy">privacy statement</Link> to see that we take data
          protection and confidentiality very seriously and on our site do not
          make further use of your social media contributions.
        </p>
      </TextPage>
    </DefaultLayout>
  )
}

export default Imprint
